import type { PageWithLayout } from '@/types/AppWithLayout';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

/**
 * Redirect legacy login page to /auth/login
 */
const LoginRedirect: PageWithLayout = () => {
  const { replace } = useRouter();
  useEffect(() => {
    replace('/auth/login');
    // eslint-disable-next-line react-hooks/exhaustive-deps -- run once on mount
  }, []);

  return null;
};

export default LoginRedirect;
